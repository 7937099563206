// Generated by Framer (4431e6b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Tab from "./JkxcN7nxk";
const TabFonts = getFonts(Tab);

const cycleOrder = ["y5Mg1v3hV", "Mq7X9HPUZ", "R5CY6MI7S"];

const variantClassNames = {Mq7X9HPUZ: "framer-v-1ggph60", R5CY6MI7S: "framer-v-1iwufjw", y5Mg1v3hV: "framer-v-1nhvdwr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Tab 1": "y5Mg1v3hV", "Tab 2": "Mq7X9HPUZ", "Tab 3": "R5CY6MI7S"};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tab1?: string; tab2?: string; tab1Link?: string; tab2Link?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "y5Mg1v3hV", tab1: MSGp_zYf0 = "Tab 1", tab2: f72tcvvi2 = "Tab 2", tab1Link: SWBmQ6R8S, tab2Link: aasIHK2ct, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "y5Mg1v3hV", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const tap1uh4r13 = activeVariantCallback(async (...args) => {
setVariant("y5Mg1v3hV")
})

const tappz11e8 = activeVariantCallback(async (...args) => {
setVariant("Mq7X9HPUZ")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-2bJ48", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1nhvdwr", className)} data-framer-name={"Tab 1"} layoutDependency={layoutDependency} layoutId={"y5Mg1v3hV"} ref={ref} style={{...style}} {...addPropertyOverrides({Mq7X9HPUZ: {"data-framer-name": "Tab 2"}, R5CY6MI7S: {"data-framer-name": "Tab 3"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1wco7ge-container"} layoutDependency={layoutDependency} layoutId={"utXsKw_4z-container"}><Tab height={"100%"} id={"utXsKw_4z"} layoutId={"utXsKw_4z"} link={SWBmQ6R8S} title={MSGp_zYf0} variant={"nRWXw6izS"} width={"100%"} {...addPropertyOverrides({Mq7X9HPUZ: {tap: tap1uh4r13, variant: "GpyNWw9GW"}, R5CY6MI7S: {tap: tap1uh4r13, variant: "GpyNWw9GW"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-1yqkv3n-container"} layoutDependency={layoutDependency} layoutId={"Tjs7gbbcB-container"}><Tab height={"100%"} id={"Tjs7gbbcB"} layoutId={"Tjs7gbbcB"} link={aasIHK2ct} tap={tappz11e8} title={f72tcvvi2} variant={"GpyNWw9GW"} width={"100%"} {...addPropertyOverrides({Mq7X9HPUZ: {variant: "nRWXw6izS"}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-2bJ48 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2bJ48 .framer-12nxfos { display: block; }", ".framer-2bJ48 .framer-1nhvdwr { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-2bJ48 .framer-1wco7ge-container, .framer-2bJ48 .framer-1yqkv3n-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-2bJ48 .framer-1nhvdwr { gap: 0px; } .framer-2bJ48 .framer-1nhvdwr > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-2bJ48 .framer-1nhvdwr > :first-child { margin-left: 0px; } .framer-2bJ48 .framer-1nhvdwr > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 49
 * @framerIntrinsicWidth 152
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"Mq7X9HPUZ":{"layout":["auto","auto"]},"R5CY6MI7S":{"layout":["auto","auto"]}}}
 * @framerVariables {"MSGp_zYf0":"tab1","f72tcvvi2":"tab2","SWBmQ6R8S":"tab1Link","aasIHK2ct":"tab2Link"}
 */
const FramereiUydUQ8a: React.ComponentType<Props> = withCSS(Component, css, "framer-2bJ48") as typeof Component;
export default FramereiUydUQ8a;

FramereiUydUQ8a.displayName = "Elements/Tabs";

FramereiUydUQ8a.defaultProps = {height: 49, width: 152};

addPropertyControls(FramereiUydUQ8a, {variant: {options: ["y5Mg1v3hV", "Mq7X9HPUZ", "R5CY6MI7S"], optionTitles: ["Tab 1", "Tab 2", "Tab 3"], title: "Variant", type: ControlType.Enum}, MSGp_zYf0: {defaultValue: "Tab 1", displayTextArea: false, title: "Tab 1", type: ControlType.String}, f72tcvvi2: {defaultValue: "Tab 2", displayTextArea: false, title: "Tab 2", type: ControlType.String}, SWBmQ6R8S: {title: "Tab 1 Link", type: ControlType.Link}, aasIHK2ct: {title: "Tab 2 Link", type: ControlType.Link}} as any)

addFonts(FramereiUydUQ8a, [...TabFonts])